<template>
  <div>
    <div class="searchBox">
      <div class="searchLeft">
        <div>
          <el-date-picker
            size="medium"
            v-model="searchForm.startTime"
            type="date"
            value-format="timestamp"
            @change="getTableList"
            placeholder="开始时间"
          ></el-date-picker>
          <span> — </span>
          <el-date-picker
            size="medium"
            v-model="searchForm.endTime"
            type="date"
            value-format="timestamp"
            @change="getTableList"
            placeholder="结束时间"
          ></el-date-picker>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList">搜索</el-button>
      </div>
      <div class="searchRight">

      </div>
    </div>
    <el-table border :data="tableData" height="76vh" style="width: 99%" @sort-change="changeSort">
      <el-table-column prop="country" label="国籍"></el-table-column>
      <el-table-column prop="nickname" label="昵称"></el-table-column>
      <el-table-column prop="language" label="语言"></el-table-column>
      <el-table-column prop="age" label="年龄"></el-table-column>
      <el-table-column prop="countryCode" label="国家代码"></el-table-column>
      <el-table-column prop="whatsApp" label="WhatsApp"></el-table-column>
      <el-table-column prop="workAnyApp" label="否是有经验">
        <template slot-scope="scope">
          <span>{{scope.row.workAnyApp ? '是' : '否'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="talent" label="专长"></el-table-column>
      <el-table-column prop="contact" label="联系方式"></el-table-column>
      <el-table-column prop="email" label="邮箱"></el-table-column>
      <el-table-column prop="createTime" label="提交时间" sortable>
        <template slot-scope="scope">
          <span>{{scope.row.createTime | formatDate}}</span>
        </template>
      </el-table-column>
    </el-table>
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
  </div>
</template>

<script>
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      totalNum: 0,
      ruleForm: {status:0},
      searchForm: {
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
    };
  },
  components:{pagination},
  created() {
    this.getTableList();
  },
  methods: {
    changeSort(val) {
      if (val.order) {
        this.searchForm.sortOrder = val.order === "ascending" ? "ascend" : "descend";
        this.searchForm.sortField = val.prop;
      } else {
        this.searchForm.sortOrder = "";
      }
      this.getTableList(true);
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.antFormList({...this.searchForm});
      if (res) {
        this.tableData = res.result.data;
        this.totalNum = res.result.totalCount;
      }
    },
  },
};
</script>

<style scoped>
.icon{
  width: 60px;
}
</style>
